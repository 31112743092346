import { useEffect, useState } from 'react';
import { AutoComplete } from '../../../components/autocomplete/AutoComplete';
import styles from './candidate-recommdation.module.css';
import { Typography } from '@mui/joy';
import { Route, Routes } from 'react-router-dom';
import { NewRecommendation } from './NewRecommendation';
import { CandidateRecommendation } from './CandidateRecommendation';
import { useUserAndProjectSelector } from '../hooks/useUserAndProjectSelector';

export function CandidateRecommendationRoutes() {
  const {
    selectedUserId,
    selectedProjectId,
    setSelectedProjectId,
    setSelectedUserId,
    allUsers,
    projects,
  } = useUserAndProjectSelector();

  return (
    <>
      <Typography level="title-md">Candidate Recommendations</Typography>
      <div className={styles.formInput}>
        <AutoComplete
          label="Select User"
          value={selectedUserId || ''}
          onChange={value => setSelectedUserId(value as string)}
          options={
            allUsers?.map(({ id, firstName, lastName }) => ({
              value: id,
              displayValue: `${firstName} ${lastName}`,
            })) || []
          }
        />
      </div>
      <div className={styles.formInput}>
        <AutoComplete
          label="Select Project"
          value={selectedProjectId || ''}
          onChange={value => setSelectedProjectId(value as string)}
          options={
            projects?.map(({ id, name }) => ({
              value: id,
              displayValue: name,
            })) || []
          }
        />
      </div>
      {selectedProjectId && selectedUserId && (
        <Routes>
          <Route
            path="new"
            element={
              <NewRecommendation
                selectedUserId={selectedUserId!}
                selectedProjectId={selectedProjectId!}
              />
            }
          />
          <Route
            path="/"
            element={<CandidateRecommendation selectedProjectId={selectedProjectId} />}
          />
        </Routes>
      )}
    </>
  );
}
