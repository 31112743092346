import { useEffect, useState } from 'react';
import { useAppStore } from '../../../store/appStore';
import { ProjectSourceScreen } from './ProjectSourceScreen';
import { SearchOnboardingPage } from './search-onboarding/SearchOnboardingPage';
import { SpinnerLoader } from '../../../components/Loader/SpinnerLoader';

export function SearchHome() {
  const projectCriteria = useAppStore(state => state.projectCriteria);
  const fetchProjectCriteria = useAppStore(state => state.fetchProjectCriteriaForm);
  const [loading, setLoading] = useState(false);

  const fetchProjectCriteriaAsync = async () => {
    setLoading(true);
    await fetchProjectCriteria();
    setLoading(false);
  };

  useEffect(() => {
    fetchProjectCriteriaAsync();
  }, []);

  return (
    <>
      {loading && <SpinnerLoader />}
      {!loading && projectCriteria?.values ? <ProjectSourceScreen /> : <SearchOnboardingPage />}
    </>
  );
}
