import { useCallback, useEffect, useState, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { Autocomplete, FormControl, FormLabel } from '@mui/joy';

export function AutoCompleteFetcher<T>({
  label,
  selectedItems,
  onSelectionChange,
  fetchItems,
  getOptionLabel,
  constructOptionFromInputValue,
  freeSolo = false,
}: {
  label: string;
  selectedItems: T[];
  onSelectionChange: (items: T[]) => void;
  fetchItems: (searchQuery: string) => Promise<T[]>;
  getOptionLabel: (item: T) => string;
  constructOptionFromInputValue?: (inputValue: string) => T;
  freeSolo?: boolean;
}) {
  // ... existing state declarations ...
  const [items, setItems] = useState<T[]>([]);
  const [itemsLoading, setItemsLoading] = useState(false);

  const fetchOptions = async (searchQuery: string) => {
    if (!searchQuery || searchQuery.length < 2) {
      setItems([]);
      return;
    }

    try {
      setItemsLoading(true);
      const items = await fetchItems(searchQuery);
      setItems(items || []);
    } catch (error) {
      console.error('Error fetching items:', error);
      setItems([]);
    } finally {
      setItemsLoading(false);
    }
  };

  const debouncedFetchItems = useMemo(() => debounce(fetchOptions, 300), []);

  useEffect(() => {
    return () => {
      debouncedFetchItems.cancel();
    };
  }, [debouncedFetchItems]);

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Autocomplete<T, true, true, typeof freeSolo | undefined>
        multiple
        options={items}
        value={selectedItems}
        type="search"
        onChange={(e, newValue) => {
          onSelectionChange(newValue as T[]);
        }}
        filterOptions={(options, params) => {
          const { inputValue } = params;
          if (inputValue !== '' && constructOptionFromInputValue) {
            options.push(constructOptionFromInputValue(inputValue));
          }

          return options;
        }}
        slotProps={{
          listbox: {
            sx: theme => ({
              zIndex: theme.vars.zIndex.modal,
            }),
          },
        }}
        sx={{
          '&::before': {
            display: 'none',
          },
          backgroundColor: 'rgb(255, 255, 255)',
          borderColor: 'rgb(228, 228, 231)',
          '&:focus-within': {
            borderColor: 'rgb(161, 161, 170)',
          },
        }}
        onInputChange={(e, value) => debouncedFetchItems(value)}
        loading={itemsLoading}
        getOptionLabel={option => getOptionLabel(option as T)}
        freeSolo={freeSolo}
      />
    </FormControl>
  );
}
