import { Box, Typography } from '@mui/joy';
import styles from '../../candidate.module.css';

interface SectionProps {
  title: string;
  items?: {
    title?: string;
    subtitle?: string;
    body?: string;
    description?: string;
    image?: JSX.Element;
  }[];
}

export function Section({ title, items }: SectionProps) {
  return (
    <div className={styles.section}>
      <SectionHeader title={title} />
      {items?.map((item, index) => (
        <Box display={'flex'} gap={1} key={index}>
          {item.image}
          <div className={styles.profileExperience}>
            {item.title && (
              <Typography level="title-sm" gutterBottom>
                {item.title}
              </Typography>
            )}
            {item.subtitle && (
              <Typography level="title-sm" gutterBottom color="neutral">
                {item.subtitle}
              </Typography>
            )}
            {item.body && (
              <Typography level="body-sm" gutterBottom>
                {item.body}
              </Typography>
            )}
            {item.description && (
              <Typography level="body-sm" gutterBottom textColor="text.primary">
                {item.description}
              </Typography>
            )}
          </div>
        </Box>
      ))}
    </div>
  );
}

export function SectionHeader({ title }: { title: string }) {
  return (
    <Typography level="title-md" fontWeight={'lg'} gutterBottom>
      {title}
    </Typography>
  );
}
