import { useCallback, useEffect, useState, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { AutoCompleteLocation, Location } from 'api-types';
import { Autocomplete, FormControl, FormLabel } from '@mui/joy';
import { fetchCompanies } from '../../../../api/api';

export function CompanyAutoComplete({
  selectedCompanies,
  onCompanyChange,
}: {
  selectedCompanies: string[];
  onCompanyChange: (companies: string[]) => void;
}) {
  // ... existing state declarations ...
  const [companyOptions, setCompanyOptions] = useState<string[]>([]);
  const [companiesLoading, setCompaniesLoading] = useState(false);

  const fetchCompanyOptions = async (searchQuery: string) => {
    if (!searchQuery || searchQuery.length < 2) {
      setCompanyOptions([]);
      return;
    }

    try {
      setCompaniesLoading(true);
      // Replace with your actual API endpoint
      const { companies } = await fetchCompanies({ prefix: searchQuery });
      const names: string[] = companies?.map(c => c.name) ?? [];
      setCompanyOptions(names || []);
    } catch (error) {
      console.error('Error fetching companies:', error);
      setCompanyOptions([]);
    } finally {
      setCompaniesLoading(false);
    }
  };

  const debouncedFetchCompanies = useMemo(() => debounce(fetchCompanyOptions, 300), []);

  useEffect(() => {
    return () => {
      debouncedFetchCompanies.cancel();
    };
  }, [debouncedFetchCompanies]);

  return (
    <FormControl>
      <FormLabel>Company Names</FormLabel>
      <Autocomplete<string, true>
        multiple
        options={companyOptions}
        value={selectedCompanies}
        onChange={(e, newValue) => {
          onCompanyChange(newValue);
        }}
        slotProps={{
          listbox: {
            sx: theme => ({
              zIndex: theme.vars.zIndex.modal,
            }),
          },
        }}
        onInputChange={(e, value) => debouncedFetchCompanies(value)}
        loading={companiesLoading}
        getOptionLabel={option => option || ''}
      />
    </FormControl>
  );
}

export function getCompanyLabel(company: string) {
  //const parts = [location.city, location.state, location.country].filter(p => !!p);
  return company;
}
