import styles from '.././outreach-editor.module.css';
import { useAppStore } from '../../../../store/appStore';
import {
  GenerateOutreachMessageResponse,
  MessageLength,
  OutreachStage as IOutreachStage,
  Tone,
  MessageIntent,
} from 'api-types';
import { Alert, Button, FormControl, Typography } from '@mui/joy';
import { CardSelect } from '../../../../components/CardSelect';
import { useImperativeHandle, useState } from 'react';
import { CardCheckbox } from '../../../../components/CardCheckbox';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { AssitantLoader } from '../../../../components/Loader/AssitantLoader';
import { TextEditor } from './TextEditor';
import { FormHelperText } from '@mui/material';
import { useStreaming } from '../../../../common/useStreaming';
import { useScrollToBottom } from '../../../../common/useScrollToBottom';
import { MESSAGE_CONTENT_LENGTH } from '.././constants';
import { Input } from '../../../../components/styled/Input';
import { StageImperativeHandler } from '../OutreachStage';

interface EmailStageEditorProps {
  currentOutreachStage?: IOutreachStage;
  stageIndex: number;
  totalNumStages: number;
  stageRef: React.Ref<StageImperativeHandler>;
  sendInterval: string | undefined;
}

export function EmailStageEditor({
  currentOutreachStage,
  stageRef,
  stageIndex,
  totalNumStages,
  sendInterval,
}: EmailStageEditorProps) {
  const currentOutreachPromptConfig = currentOutreachStage?.messageConfig?.promptConfig;
  const generateOutreachMessage = useAppStore(state => state.generateOutreachMessage);
  const [messageLength, setMessageLength] = useState<MessageLength | undefined>(
    currentOutreachPromptConfig?.length
  );
  const [tone, setTone] = useState<Tone | undefined>(currentOutreachPromptConfig?.tone);
  const [includeSkills, setIncludeSkills] = useState(
    currentOutreachPromptConfig?.candidateDetails?.includeSkills
  );
  const [includeExperience, setIncludeExperience] = useState(
    currentOutreachPromptConfig?.candidateDetails?.includeExperience
  );
  const [includeEducation, setIncludeEducation] = useState(
    currentOutreachPromptConfig?.candidateDetails?.includeEducation
  );
  const [includeCompanyPerks, setIncludeCompanyPerks] = useState(
    currentOutreachPromptConfig?.companyDetails?.includeCompanyPerks
  );
  const [includeCompanyFunding, setIncludeCompanyFunding] = useState(
    currentOutreachPromptConfig?.companyDetails?.includeCompanyFunding
  );
  const [includeCompanySummary, setIncludeCompanySummary] = useState(
    currentOutreachPromptConfig?.companyDetails?.includeCompanySummary
  );
  const [aiGeneratedMessage, setAiGeneratedMessage] = useState(
    currentOutreachStage?.messageConfig?.aiGeneratedMessage || ''
  );

  // TODO(amethaila): When calender link is setup in in user profile, convert this to boolean.
  const [calenderLink, setCalenderLink] = useState(currentOutreachPromptConfig?.calenderLink);
  const [subject, setSubject] = useState(currentOutreachStage?.messageConfig?.subject || '');
  const {
    message,
    streamingError,
    loading,
    onInitializeStreaming,
    onChunkReceive,
    onDone,
    onError,
  } = useStreaming();
  const { containerRef: editorContainerRef, scrollToBottom } = useScrollToBottom();
  const streamingMessage = message.current;

  const onMessageChunkReceive = ({ content }: GenerateOutreachMessageResponse) => {
    onChunkReceive(content!);
    scrollToBottom();
  };

  const onMessageStreamDone = () => {
    setAiGeneratedMessage(message.current);
    onDone();
  };

  function onMessageStreamError() {
    onError();
  }

  const handleMessageGenerate = () => {
    onInitializeStreaming();
    generateOutreachMessage(
      {
        subject,
        promptConfig: {
          tone,
          length: messageLength,
          candidateDetails: {
            includeEducation,
            includeExperience,
            includeSkills,
          },
          companyDetails: {
            includeCompanyFunding,
            includeCompanyPerks,
            includeCompanySummary,
          },
          messageIntent: calculateMessageIntent(stageIndex, totalNumStages),
          calenderLink,
        },
      },
      {
        onMessage: onMessageChunkReceive,
        onDone: onMessageStreamDone,
        onError: onMessageStreamError,
      }
    );
  };

  useImperativeHandle(stageRef, () => ({
    getStageConfig: () => ({
      id: currentOutreachStage?.id,
      sendInterval: sendInterval,
      outreachType: 'email',
      messageConfig: {
        promptConfig: {
          tone,
          length: messageLength,
          candidateDetails: {
            includeEducation,
            includeExperience,
            includeSkills,
          },
          companyDetails: {
            includeCompanyFunding,
            includeCompanyPerks,
            includeCompanySummary,
          },
          calenderLink,
        },
        subject,
        aiGeneratedMessage,
      },
    }),
  }));
  return (
    <>
      <div className={styles.emailStageContainer}>
        <div className={styles.emailFormContainer}>
          <div className={styles.formInput}>
            <CardSelect
              label="Message Length"
              options={Object.keys(MessageLength).map(key => ({
                value: key,
                content: MESSAGE_CONTENT_LENGTH[key as MessageLength].content,
                heading: MESSAGE_CONTENT_LENGTH[key as MessageLength].title,
              }))}
              value={messageLength}
              onChange={val => setMessageLength(val as MessageLength)}
            />
          </div>
          <div className={styles.formInput}>
            <CardSelect
              label="Tone"
              options={Object.keys(Tone).map(key => ({
                value: key,
                heading: key,
              }))}
              value={tone}
              onChange={val => setTone(val as Tone)}
            />
          </div>
          <div className={styles.messageToggleContainer}>
            <Typography level="title-sm" fontWeight={'lg'} color="neutral" gutterBottom>
              Candidate details included in the message
            </Typography>
            <div className={styles.messageToggleInputs}>
              <CardCheckbox
                value={includeSkills}
                card={{
                  heading: 'Skills',
                  content: 'Include candidate skills in the outgoing message ',
                }}
                onChange={val => setIncludeSkills(val)}
              />
              <CardCheckbox
                value={includeExperience}
                card={{
                  heading: 'Experience',
                  content: 'Include candidate experience in the outgoing message ',
                }}
                onChange={val => setIncludeExperience(val)}
              />
              <CardCheckbox
                value={includeEducation}
                card={{
                  heading: 'Education',
                  content: 'Include candidate education in the outgoing message ',
                }}
                onChange={val => setIncludeEducation(val)}
              />
            </div>
          </div>
          <div className={styles.messageToggleContainer}>
            <Typography level="title-sm" fontWeight={'lg'} color="neutral" gutterBottom>
              Company details included in the message
            </Typography>
            <div className={styles.messageToggleInputs}>
              <CardCheckbox
                value={includeCompanySummary}
                card={{
                  heading: 'Company summary',
                  content: 'Include company summary in the outgoing message ',
                }}
                onChange={val => setIncludeCompanySummary(val)}
              />
              <CardCheckbox
                value={includeCompanyFunding}
                card={{
                  heading: 'Company funding ',
                  content:
                    'Include company funding stage and growth details in the outgoing message',
                }}
                onChange={val => setIncludeCompanyFunding(val)}
              />
              <CardCheckbox
                value={includeCompanyPerks}
                card={{
                  heading: 'Company perks & benefits',
                  content: 'Include company perks & benefits in the outgoing message',
                }}
                onChange={val => setIncludeCompanyPerks(val)}
              />
            </div>
          </div>
          <FormControl>
            <Typography level="title-sm" fontWeight={'lg'} color="neutral" gutterBottom>
              Add calender link (Optional)
            </Typography>
            <Input
              value={calenderLink}
              onChange={e => setCalenderLink(e.target.value)}
              placeholder="www.calendly.com/abc/"
            />
            <FormHelperText>
              This can be used to add a scheduling calender link in the email.
            </FormHelperText>
          </FormControl>
          <div className={styles.actionBarContainer}>
            <Button
              color="neutral"
              variant="outlined"
              onClick={handleMessageGenerate}
              startDecorator={<AutoAwesomeOutlinedIcon />}
              disabled={loading}
            >
              Generate
            </Button>
          </div>
        </div>

        <div className={styles.textEditorContainer} ref={editorContainerRef}>
          <div className={styles.textEditor}>
            <TextEditor
              subject={subject}
              setSubject={setSubject}
              message={loading && streamingMessage ? streamingMessage : aiGeneratedMessage}
            />
          </div>
          {loading && (
            <div className={styles.generatedMessageLoader}>
              <AssitantLoader />
            </div>
          )}

          {streamingError && (
            <Alert color="danger" variant="soft">
              There was an error in getting the response, please try again.
            </Alert>
          )}
        </div>
      </div>
    </>
  );
}

export const calculateMessageIntent = (index: number, length: number) => {
  if (index === 0) {
    return MessageIntent.Introduction;
  }
  if (index === length - 1) {
    return MessageIntent.LastFollowUp;
  }
  return MessageIntent.FollowUp;
};
