import styles from '../create-project/create-project.module.css';
import { useState } from 'react';
import { Project } from 'api-types';
import SaveIcon from '@mui/icons-material/Save';
import { FormControl, FormLabel, Button } from '@mui/joy';
import { Input } from '../../../components/styled/Input';
import { useAppStore } from '../../../store/appStore';
import { useSnackbar } from '../../../components/Snackbar/SnackbarContext';

interface CreateProjectForm {
  project?: Project;
  onSubmitSuccess?: (project: Project | undefined) => void;
}

export function ProjectForm(props: CreateProjectForm) {
  const { project, onSubmitSuccess } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const upsertProject = useAppStore(state => state.upsertProject);

  const [projectName, setProjectName] = useState(project?.name || '');
  const { showSuccessSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const createdProject = await upsertProject(
        {
          name: projectName,
        },
        project?.id
      );
      showSuccessSnackbar(
        project?.id ? 'Project updated successfully' : 'Project created successfully'
      );
      onSubmitSuccess?.(createdProject);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className={styles.searchFormContainer}>
        <FormControl>
          <FormLabel>Project Name</FormLabel>
          <Input
            variant="outlined"
            value={projectName}
            onChange={e => setProjectName(e.target.value)}
          />
        </FormControl>
      </div>
      <div className={styles.searchActionBar}>
        <Button
          variant="outlined"
          color="neutral"
          onClick={handleSubmit}
          disabled={!projectName}
          loading={isSubmitting}
          startDecorator={<SaveIcon />}
        >
          {project ? 'Update' : 'Create'}
        </Button>
      </div>
    </>
  );
}
