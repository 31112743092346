import { StoreApi } from 'zustand';
import { AppStore } from '../../../store/types';
import {
  Candidate,
  CandidateStage,
  FileContent,
  LLMAugmentedCandidate,
  PipelineStageEnum,
} from 'api-types';
import {
  batchUpdateCandidateStages,
  batchUploadCandidates,
  getCandidatePipeline,
  searchCandidatesByProject,
} from '../../../api/api';
import { CandidateSlice, SearchCandidateParams } from './types';

const initialState: Partial<CandidateSlice> = {
  searchCandidateResult: undefined,
  candidatePipeline: undefined,
};

export const SEARCH_CANDIDATES_PAGE_SIZE = 10;

export const createCandidateSlice = (
  set: StoreApi<CandidateSlice>['setState'],
  get: StoreApi<AppStore>['getState']
): CandidateSlice => {
  function filterSearchCandidateResult(filterFn: (candidate: LLMAugmentedCandidate) => boolean) {
    const { searchCandidateResult } = get();
    set({
      searchCandidateResult: {
        currentOffset: searchCandidateResult?.currentOffset,
        totalCount: searchCandidateResult?.totalCount,
        candidates: searchCandidateResult?.candidates?.filter(filterFn),
      },
    });
  }
  return {
    ...initialState,
    resetCandidateSlice() {
      set({
        ...initialState,
      });
    },
    searchCandidates: async ({ offset, size }: SearchCandidateParams) => {
      let { activeProjectId, searchCandidateResult: existingSearchCandidateResult } = get();
      if (activeProjectId) {
        set({
          searchCandidateResult: {
            currentOffset: offset,
            candidates: undefined,
            totalCount: existingSearchCandidateResult?.totalCount,
          },
        });
        const { candidates, totalCount, projectId, llmAugmentedCandidates } =
          await searchCandidatesByProject({
            projectId: activeProjectId,
            offset: offset,
            size: size || SEARCH_CANDIDATES_PAGE_SIZE,
          });
        const { searchCandidateResult, activeProjectId: latestActiveProjectId } = get();
        if (latestActiveProjectId !== projectId) {
          return;
        }

        if (searchCandidateResult?.currentOffset === offset) {
          set({
            searchCandidateResult: {
              currentOffset: offset,
              totalCount: totalCount || 0,
              candidates: llmAugmentedCandidates || [],
            },
          });
        }
      }
    },
    fetchCandidatePipeline: async () => {
      const { activeProjectId } = get();
      if (activeProjectId) {
        const { candidatePipeline } = await getCandidatePipeline({ projectId: activeProjectId });
        const { activeProjectId: latestActiveProjectId } = get();
        if (candidatePipeline?.projectId === latestActiveProjectId) {
          set({
            candidatePipeline,
          });
        }
        return candidatePipeline;
      }
    },
    batchUpdateCandidateStages: async (
      candidateStageItems: { candidateId: string; stage: PipelineStageEnum; notes?: string }[]
    ) => {
      const { activeProjectId } = get();
      if (activeProjectId) {
        await batchUpdateCandidateStages({
          candidateStageItems: candidateStageItems.map(item => ({
            candidateStage: {
              stage: item.stage,
              notes: item.notes,
            },
            candidateId: item.candidateId,
          })),
          projectId: activeProjectId,
        });
        const { activeProjectId: latestActiveProjectId } = get();
        if (latestActiveProjectId === activeProjectId) {
          const candidateIds = candidateStageItems.map(item => item.candidateId);
          candidateIds.forEach(candidateId => {
            filterSearchCandidateResult(candidate => candidate.candidate?.id !== candidateId);
          });
        }
      }
    },
    batchUploadCandidates: async (candidates: FileContent[], candidateStage?: CandidateStage) => {
      const { activeProjectId } = get();
      if (activeProjectId) {
        await batchUploadCandidates({
          projectId: activeProjectId,
          unstructuredCandidates: candidates,
          candidateStage,
        });
      }
    },
  };
};
