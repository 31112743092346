import { Input as MuiInput, InputProps, styled } from '@mui/joy';

export const Input = styled(MuiInput)`
  &::before {
    display: none;
  }

  background-color: rgb(255, 255, 255);
  border-color: rgb(228, 228, 231);

  &:focus-within {
    border-color: rgb(161, 161, 170);
  }
`;
