import { CircularProgress } from '@mui/joy';
import styles from './loader.module.css';

export function SpinnerLoader({ size }: { size?: 'sm' | 'md' | 'lg' } = { size: 'md' }) {
  return (
    <div className={styles.loader}>
      <CircularProgress size={size} />
    </div>
  );
}
