import { useEffect, useState } from 'react';
import { useAppStore } from '../../../../store/appStore';
import { Outreach, OutreachMessage } from 'api-types';
import { getAllOutreachMessages, getOutreach } from '../../../../api/api';
import { add, parseISO } from "date-fns";

interface MessageWithSubject extends OutreachMessage {
  subject?: string; 
}

export function useLatestOutreachMessage(candidateId: string) {
  const getAllOutreachMessages = useAppStore(state => state.getAllOutreachMessages);
  const outreach = useAppStore(state => state.outreach);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<MessageWithSubject[]>();

  const fetchMessages = async () => {
    setLoading(true);
    try {
      const messages = await getAllOutreachMessages(candidateId!);
      
      const messagesWithSubjects = messages?.map((message, index) => {
        const stageIndex = outreach?.stages ? 
          Math.min(index, outreach.stages.length - 1) : 
          0;
        
        return {
          ...message,
          subject: outreach?.stages?.[stageIndex]?.messageConfig?.subject || 'No Subject',
        };
      });

      setMessages(messagesWithSubjects);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [candidateId, outreach]);

  return {
    messages,
    loading,
  };
}

export function useLatestOutreachMessageByProjectId(projectId: string, candidateId: string) {
  const [loading, setLoading] = useState(false);

  const [messages, setMessages] = useState<OutreachMessage[]>();
  const [outreach, setOutreach] = useState<Outreach>();

  const fetchMessages = async () => {
    setLoading(true);
    try {
      const { outreachMessages } = await getAllOutreachMessages({
        projectId,
        candidateId,
      });
      const { outreach } = await getOutreach({ projectId });
      setMessages(outreachMessages);
      setOutreach(outreach);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [projectId, candidateId]);

  const lastMessage = messages?.[messages.length - 1];
  const lastStage = outreach?.stages?.[outreach.stages.length - 1];

  return {
    message: lastMessage,
    subject: lastStage?.messageConfig?.subject,
    loading,
  };
}
