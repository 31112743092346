import styles from './search-bar.module.css';
import SendIcon from '@mui/icons-material/Send';
import { SpinnerLoader } from '../../../../../components/Loader/SpinnerLoader';
import { Input } from 'components/styled/Input';

interface SearchBarProps {
  searchQuery: string;
  onChange: (query: string) => void;
  onSubmit: () => void;
  submitting?: boolean;
}

export function SearchBar({ searchQuery, onChange, onSubmit, submitting }: SearchBarProps) {
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Input
      size="lg"
      sx={{
        'border-width': '2px',
        '&:focus-within': {
          borderWidth: '2px',
        },
        width: '80%',
      }}
      className={styles.searchInput}
      onKeyDown={onKeyDown}
      disabled={submitting}
      color="neutral"
      placeholder="Search for candidates. Example: Find me software engineers working in series a startups?"
      endDecorator={
        submitting ? (
          <SpinnerLoader size="sm" />
        ) : (
          <SendIcon
            onClick={onSubmit}
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
              color: 'rgb(24, 24, 27)',
            }}
          />
        )
      }
      onChange={e => onChange(e.target.value)}
      value={searchQuery}
    />
  );
}
