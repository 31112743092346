import styles from './candidate.module.css';
import { Box, Button, Card, CardContent, Divider, Typography } from '@mui/joy';
import { Candidate, LLMAugmentedCandidate } from 'api-types';
import { useState } from 'react';
import { CandidateProfile, TabEnum } from './profile/CandidateProfile';
import { Drawer } from '@mui/material';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { LinkedInLogo } from '../../components/Logo/LinkedinLogo';
import { humanizeDateRange } from './utils';
import { RatingChip } from '../outreach/pipeline/components/PipelineChips';
import { Image } from '../../components/Image/Image';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import emptyCompanyLogo from '../../assets/empty_company_logo.jpeg';
import { useFeatureParams } from '../../common/useFeatureParam';

interface CandidateListItemProps {
  candidate: LLMAugmentedCandidate;
  renderActions?: (candidate: Candidate) => React.ReactNode;
}

export function CandidateListItem({
  candidate: llmAugmentedCandidate,
  renderActions,
}: CandidateListItemProps) {
  const { candidate, llmReasoning, llmScore, llmSummary } = llmAugmentedCandidate;

  const [profileOpen, setProfileOpen] = useState(false);
  const [defaultProfileTab, setDefaultProfileTab] = useState<TabEnum>(TabEnum.Profile);
  const { searchDemoImageMode } = useFeatureParams();

  if (!candidate) {
    return null;
  }

  const topTwoExperiences = candidate.experiences?.slice(0, 2);
  const highestEducation =
    candidate.education && candidate.education.length > 0 ? candidate.education[0] : undefined;

  const onRatingClick = () => {
    setDefaultProfileTab(TabEnum.Reasoning);
    setProfileOpen(true);
  };
  return (
    <>
      <Card
        className={styles.card}
        key={candidate.id}
        sx={{ backgroundColor: '#fff' }}
        id="candidate-list-item-card"
      >
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems={'center'}>
            <div>
              <Typography
                level="title-md"
                fontWeight={'lg'}
                gutterBottom
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {`${candidate.firstName} ${candidate.lastName || ''}`}
                {candidate?.linkedinUrl && <LinkedInLogo url={candidate?.linkedinUrl} />}
              </Typography>
              <div className={styles.cardHeading}>
                <Typography level="body-sm" gutterBottom>
                  {candidate.experiences?.[0]?.position}
                </Typography>
                <Typography level="body-sm" gutterBottom>
                  {candidate.city}, {candidate.state}
                </Typography>
              </div>
            </div>
            {renderActions?.(candidate)}
          </Box>

          <Divider />
          <div className={styles.candidateRow}>
            <div>
              {llmSummary && (
                <div className={styles.section}>
                  {!searchDemoImageMode && (
                    <>
                      <SectionHeader
                        title="AI Summary"
                        startDecorator={<AutoAwesomeOutlinedIcon fontSize="small" />}
                      />
                      {llmScore && <RatingChip llmScore={llmScore} onClick={onRatingClick} />}
                    </>
                  )}
                  <Typography level="body-sm">{llmSummary}</Typography>
                </div>
              )}
              <div className={styles.flexSection}>
                <SectionHeader title="Experience" />
                {topTwoExperiences && (
                  <SectionList
                    sectionItems={topTwoExperiences?.map(e => {
                      const image = (
                        <Image
                          src={e.logoUrl || emptyCompanyLogo}
                          width={16}
                          height={16}
                          alt={''}
                          fallback={null}
                        />
                      );
                      const contents: (string | JSX.Element)[] = [
                        `${e.position} at ${e.company}`,
                        e.startDate ? humanizeDateRange(e.startDate, e.endDate) : '',
                      ];
                      if (image !== null) {
                        contents.unshift(image);
                      }
                      return {
                        contents,
                      };
                    })}
                  />
                )}
              </div>

              {/* {highestEducation && (
                <div className={styles.flexSection}>
                  <SectionHeader title="Education" />
                  <SectionList
                    sectionItems={[
                      {
                        contents: [
                          `${pascalCase(highestEducation.degree || '')} at ${highestEducation.institute}`,
                        ],
                      },
                    ]}
                  />
                </div>
              )} */}
            </div>
            {!searchDemoImageMode && (
              <div className={styles.candidateItemfooter}>
                <Button
                  variant="plain"
                  color="neutral"
                  size="sm"
                  onClick={() => setProfileOpen(true)}
                >
                  View full profile
                </Button>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
      <Drawer anchor={'right'} open={Boolean(profileOpen)} onClose={() => setProfileOpen(false)}>
        <div className={styles.drawerContainer}>
          <CandidateProfile
            candidate={llmAugmentedCandidate}
            renderReasoning
            defaultTab={defaultProfileTab}
          />
        </div>
      </Drawer>
    </>
  );
}

export function SectionHeader({
  title,
  startDecorator,
}: {
  title: string;
  startDecorator?: React.ReactNode;
}) {
  return (
    <Typography
      level="body-sm"
      className={styles.sectionHeader}
      fontWeight={'lg'}
      gutterBottom
      startDecorator={startDecorator}
    >
      {title}
    </Typography>
  );
}

export function SectionList({
  sectionItems,
}: {
  sectionItems: { contents: (string | JSX.Element)[] }[];
}) {
  return (
    <div>
      {sectionItems?.map((item, index) => (
        <Box display={'flex'} gap={1} key={index} alignItems={'center'} marginBottom={1}>
          <FiberManualRecordOutlinedIcon
            fontSize="small"
            sx={{ fontSize: '8px', color: 'var(--joy-palette-neutral-500)' }}
          />
          <div className={styles.experience}>
            {item.contents.map(content => (
              <Typography
                level="body-sm"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                {content}
              </Typography>
            ))}
          </div>
        </Box>
      ))}
    </div>
  );
}
