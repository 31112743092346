import { AppBar, Box, Toolbar } from '@mui/material';
import { Link, useMatch, useParams } from 'react-router-dom';
import styles from './project.module.css';
import { DRAWER_WIDTH } from '../../../constants';
import { useAppStore } from '../../../store/appStore';
import { useEffect, useState } from 'react';
import { Tab, Tabs, tabClasses } from '@mui/joy';
import { ProjectRoutes } from '../routes/ProjectRoutes';
import { TabList } from '../../../components/Tabs/TabList';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface CandidateCriteria {
  candidateRole: string;
  minExp: number;
  maxExp: number;
}

enum ProjectNavigation {
  SEARCH = 0,
  OUTREACH = 1,
}

export function ProjectHome() {
  const { projectId } = useParams();
  const project = useAppStore(state => state.project);
  const setActiveProjectId = useAppStore(state => state.setActiveProjectId);
  const fetchProject = useAppStore(state => state.fetchProject);

  const isSearchPath = useMatch({ path: '/project/:projectId' });
  const [selectedNav, setSelectedNav] = useState(
    isSearchPath ? ProjectNavigation.SEARCH : ProjectNavigation.OUTREACH
  );

  useEffect(() => {
    setSelectedNav(isSearchPath ? ProjectNavigation.SEARCH : ProjectNavigation.OUTREACH);
  }, [isSearchPath]);

  useEffect(() => {
    async function fetchProjectAsync() {
      if (projectId) {
        setActiveProjectId(projectId);
        await fetchProject();
      }
    }
    fetchProjectAsync();
  }, [projectId]);

  return (
    <div className={styles.projectContainer} id="project-container">
      {project && (
        <>
          <AppBar
            position="fixed"
            sx={{ width: `calc(100% - ${DRAWER_WIDTH}px)`, ml: `${DRAWER_WIDTH}px` }}
            className={styles.appBar}
          >
            <Toolbar>
              <Box sx={{ alignSelf: 'flex-end', flexGrow: 1, borderColor: 'divider' }}>
                <Tabs value={selectedNav} sx={{ backgroundColor: 'transparent' }}>
                  <TabList className={styles.tabList}>
                    <Tab component={Link} to={''}>
                      Search
                    </Tab>
                    <Tab component={Link} to={'outreach'}>
                      Outreach
                    </Tab>
                  </TabList>
                </Tabs>
              </Box>
            </Toolbar>
          </AppBar>
          <ProjectRoutes />
        </>
      )}
    </div>
  );
}
