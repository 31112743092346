import { DialogTitle, Modal, ModalDialog, Typography } from '@mui/joy';
import { DialogContent } from '@mui/material';
import { ProjectCriteriaValues } from 'api-types';
import { CriteriaForm } from '../../criteria-form/CriteriaForm';
import styles from './SearchOnboarding.module.css';
import { Dialog } from 'shadcn/components/ui/dialog';

export function DraftCriteriaModal({
  open,
  onClose,
  draftCriteria,
}: {
  open: boolean;
  onClose: () => void;
  draftCriteria: ProjectCriteriaValues;
}) {
  console.log(draftCriteria);
  return (
    <Modal open={open} onClose={onClose} sx={{ backdropFilter: 'blur(4px)' }}>
      <ModalDialog sx={{ background: '#ffffff' }}>
        <div className={styles.dialogContainer}>
          <DialogTitle sx={{ padding: '0 20px' }}>Search Filters</DialogTitle>
          <CriteriaForm criteria={draftCriteria} />
        </div>
      </ModalDialog>
    </Modal>
  );
}
