import { Autocomplete, AutocompleteRenderOptionState, FormControl, FormLabel } from '@mui/joy';
import { DropdownOption } from 'api-types';
import React from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ChipSelectProps {
  label: string;
  value: string[] | string;
  onChange: (value: string[] | string) => void;
  isMultiple?: boolean;
  options: DropdownOption[];
  placeholder?: string;
  size?: 'small' | 'medium' | 'large';
  renderOption?: (
    prop: any,
    option: DropdownOption,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
}

export function AutoComplete<T>(props: ChipSelectProps) {
  const { label, value, onChange, options, isMultiple, placeholder, size } = props;
  const values: DropdownOption[] = options?.filter(o => {
    if (typeof value === 'string') {
      return o.value === value;
    } else {
      return value.includes(o.value!);
    }
  });

  const handleChange = (event: any, value: DropdownOption | DropdownOption[] | null) => {
    if (!value) {
      if (isMultiple) {
        onChange([]);
      } else {
        onChange('');
      }
      return;
    }
    if (Array.isArray(value)) {
      onChange(value.map(v => v.value!));
    } else {
      onChange(value.value!);
    }
  };
  const width = size === 'small' ? 160 : size === 'medium' ? 240 : 500;

  return (
    <FormControl sx={{ width: width }}>
      <FormLabel>{label}</FormLabel>
      <Autocomplete
        sx={{
          '&::before': {
            display: 'none',
          },
          backgroundColor: 'rgb(255, 255, 255)',
          borderColor: 'rgb(228, 228, 231)',
          '&:focus-within': {
            borderColor: 'rgb(161, 161, 170)',
          },
        }}
        autoComplete
        multiple={isMultiple}
        options={options}
        value={typeof value === 'string' ? values?.[0] || null : values}
        slotProps={{
          listbox: {
            sx: theme => ({
              zIndex: theme.vars.zIndex.modal,
            }),
          },
        }}
        onChange={handleChange}
        renderOption={props.renderOption}
        getOptionLabel={option => option?.displayValue!}
      />
    </FormControl>
  );
}
