import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface FeatureParams {
  enableAutopilot: boolean;
  searchDemoImageMode: boolean;
}

export function useFeatureParams(): FeatureParams {
  const [searchParams] = useSearchParams();
  const localStorage = window.localStorage;

  useEffect(() => {
    if (searchParams && searchParams.has('enableAutopilot')) {
      localStorage.setItem('enableAutopilot', searchParams.get('enableAutopilot') || 'false');
    }
    if (searchParams && searchParams.has('searchDemoImageMode')) {
      localStorage.setItem(
        'searchDemoImageMode',
        searchParams.get('searchDemoImageMode') || 'false'
      );
    }
  }, [searchParams]);

  return {
    enableAutopilot: localStorage.getItem('enableAutopilot') === 'true',
    searchDemoImageMode: localStorage.getItem('searchDemoImageMode') === 'true',
  };
}
