import styles from './criteria-form.module.css';
import { useAppStore } from '../../../store/appStore';
import { useEffect, useState } from 'react';
import { AutoComplete } from '../../../components/autocomplete/AutoComplete';
import {
  AutoCompleteLocation,
  CandidateCriteria,
  ProjectCriteriaValues,
  UpsertProjectCriteriaObject,
  Location,
} from 'api-types';
import {
  Typography,
  Button,
  FormControl,
  FormLabel,
  Autocomplete as JoyAutocomplete,
} from '@mui/joy';
import { Textarea } from '../../../components/styled/TextArea';
import { SpinnerLoader } from '../../../components/Loader/SpinnerLoader';
import { useSnackbar } from '../../../components/Snackbar/SnackbarContext';
import { CompanyAutoComplete } from './components/CompanyAutoComplete';
import { AutoCompleteFetcher } from 'components/autocomplete/AutoCompleteFetcher';
import { fetchJobTitles, fetchLocations, fetchSchools, fetchSkills } from 'api/api';

export function CriteriaForm(props: { criteria?: ProjectCriteriaValues }) {
  const criteria = useAppStore(state => state.projectCriteria);

  const { formSchema } = criteria || {};
  const values = props.criteria || criteria?.values;
  console.log(values, 'bhenchod values');
  const { candidateCriteria, companyCriteria, educationCriteria } = values || {};
  const [jobTitles, setJobTitles] = useState(candidateCriteria?.jobTitles || []);
  const [yearsOfExperiences, setYearsOfExperiences] = useState(
    candidateCriteria?.yearsOfExperiences || []
  );
  const [locations, setLocations] = useState<AutoCompleteLocation[]>(
    mappedLocations(candidateCriteria) || []
  );

  const [skills, setSkills] = useState(candidateCriteria?.skills || []);
  const [companyNames, setCompanyNames] = useState(companyCriteria?.companyNames || []);
  const [companySizes, setCompanySizes] = useState(companyCriteria?.companySizes || []);
  const [companyFundingStages, setCompanyFundingStages] = useState(
    companyCriteria?.companyFundingStages || []
  );
  const [schoolNames, setSchoolNames] = useState(educationCriteria?.schools || []);
  const [degrees, setDegrees] = useState(educationCriteria?.degrees || []);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const { showSuccessSnackbar } = useSnackbar();

  const updateProjectCriteria = useAppStore(state => state.updateProjectCriteria);
  const fetchProjectCriteria = useAppStore(state => state.fetchProjectCriteriaForm);

  const handleSubmit = async () => {
    const draftCriteria: UpsertProjectCriteriaObject = {
      candidateCriteria: {
        jobTitles,
        yearsOfExperiences,
        locations,
        skills,
      },
      companyCriteria: {
        companyNames,
        companySizes,
        companyFundingStages,
      },
      educationCriteria: {
        degrees,
      },
    };
    try {
      setUpdating(true);
      await updateProjectCriteria(draftCriteria);
      showSuccessSnackbar('Project criteria updated successfully');
    } finally {
      setUpdating(false);
    }
  };

  const getProjectCriteria = async () => {
    try {
      setLoading(true);
      const projectCriteriaForm = await fetchProjectCriteria();
      const { candidateCriteria, companyCriteria, educationCriteria } =
        projectCriteriaForm?.values || {};
      setJobTitles(candidateCriteria?.jobTitles || []);
      setYearsOfExperiences(candidateCriteria?.yearsOfExperiences || []);
      setSkills(candidateCriteria?.skills || []);
      setLocations(mappedLocations(candidateCriteria) || []);
      setDegrees(educationCriteria?.degrees || []);
      setCompanyFundingStages(companyCriteria?.companyFundingStages || []);
      setCompanyNames(companyCriteria?.companyNames || []);
      setCompanySizes(companyCriteria?.companySizes || []);
      setSchoolNames(educationCriteria?.schools || []);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!criteria) {
      getProjectCriteria();
    }
  }, []);

  return loading ? (
    <SpinnerLoader />
  ) : (
    <div className={styles.editProjectContainer}>
      <div className={styles.formInput}>
        <AutoCompleteFetcher<string>
          label="Job Titles"
          selectedItems={jobTitles}
          onSelectionChange={value => setJobTitles(value as string[])}
          fetchItems={async (searchQuery: string) => {
            const { values } = await fetchJobTitles({ prefix: searchQuery });
            return values?.map(value => value.value || '') || [];
          }}
          getOptionLabel={option => option || ''}
          freeSolo
          constructOptionFromInputValue={inputValue => inputValue}
        />
      </div>
      <div className={styles.formInput}>
        <AutoCompleteFetcher<string>
          label="Skills"
          selectedItems={skills}
          onSelectionChange={setSkills}
          fetchItems={async (searchQuery: string) => {
            const { values } = await fetchSkills({ prefix: searchQuery });
            return values?.map(value => value.value || '') || [];
          }}
          getOptionLabel={option => option || ''}
          freeSolo
          constructOptionFromInputValue={inputValue => inputValue}
        />
      </div>
      <div className={styles.formInput}>
        <AutoCompleteFetcher<AutoCompleteLocation>
          label="Location"
          selectedItems={locations}
          onSelectionChange={setLocations}
          fetchItems={async (searchQuery: string) => {
            const { locations } = await fetchLocations({ prefix: searchQuery });
            return locations || [];
          }}
          getOptionLabel={option => option.displayValue || ''}
        />
      </div>
      <div className={styles.formInput}>
        <AutoComplete
          label="Years of experience"
          value={yearsOfExperiences}
          isMultiple
          onChange={value => setYearsOfExperiences(value as string[])}
          options={formSchema?.yearsOfExperienceOptions || []}
        />
      </div>
      <div className={styles.formInput}>
        <AutoComplete
          label="Company Funding Stage"
          value={companyFundingStages}
          isMultiple
          onChange={value => setCompanyFundingStages(value as string[])}
          options={formSchema?.companyFundingStageOptions || []}
        />
      </div>
      <div className={styles.formInput}>
        <AutoComplete
          label="Company Size"
          value={companySizes}
          isMultiple
          onChange={value => setCompanySizes(value as string[])}
          options={formSchema?.companySizeOptions || []}
        />
      </div>
      <div className={styles.formInput}>
        <CompanyAutoComplete selectedCompanies={companyNames} onCompanyChange={setCompanyNames} />
      </div>
      <div className={styles.formInput}>
        <AutoCompleteFetcher<string>
          label="School Names"
          selectedItems={schoolNames}
          onSelectionChange={setSchoolNames}
          fetchItems={async (searchQuery: string) => {
            const { values } = await fetchSchools({ prefix: searchQuery });
            return values?.map(value => value.value || '') || [];
          }}
          getOptionLabel={option => option || ''}
          freeSolo
          constructOptionFromInputValue={inputValue => inputValue}
        />
      </div>
      <div className={styles.formInput}>
        <AutoComplete
          label="Degrees"
          value={degrees}
          isMultiple
          onChange={value => setDegrees(value as string[])}
          options={formSchema?.degreeOptions || []}
        />
      </div>

      <div className={styles.editProjectActionBar}>
        <Button onClick={handleSubmit} disabled={updating}>
          Submit
        </Button>
      </div>
    </div>
  );
}

const mappedLocations = (candidateCriteria: CandidateCriteria | undefined) =>
  candidateCriteria?.locations?.map(location => ({
    value: location.placeId,
    displayValue: getLocationLabel(location),
  }));

function getLocationLabel(location: Location) {
  const parts = [location.city, location.state, location.country].filter(p => !!p);
  return parts.join(', ');
}
