import { CandidateItem, LLMAugmentedCandidate } from 'api-types';
import styles from '.././candidate.module.css';
import { Tab, TabPanel, Tabs, Typography, Box, Card, CardContent } from '@mui/joy';
import { useState } from 'react';
import { SpinnerLoader } from '../../../components/Loader/SpinnerLoader';
import { useLatestOutreachMessage } from '../../outreach/outreach-thread/hooks/useLatestOutreachMessage';
import { usePhoneConversationSummary } from '../../outreach/outreach-thread/hooks/usePhoneConversationSummary';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { TabList } from '../../../components/Tabs/TabList';
import { CandidateProfileDetails } from './components/CandidateProfileDetails';
import { CandidateHeaderDetails } from './components/CandidateHeaderDetails';
import { ContentBox } from './components/ContentBox';

interface CandidateProfileProps {
  candidate: LLMAugmentedCandidate;
  renderMessages?: boolean;
  renderReasoning?: boolean;
  renderPhoneSummary?: boolean;
  defaultTab?: TabEnum;
}

export enum TabEnum {
  Profile = 'profile',
  Message = 'message',
  Reasoning = 'reasoning',
  PhoneSummary = 'phone-summary',
}

export function CandidateProfile({
  candidate: llmCandidate,
  renderMessages,
  renderReasoning,
  renderPhoneSummary,
  defaultTab,
}: CandidateProfileProps) {
  const { candidate, llmReasoning } = llmCandidate;
  const [selectedTab, setSelectedTab] = useState<TabEnum>(defaultTab || TabEnum.Profile);
  const [selectedMessageId, setSelectedMessageId] = useState<string | null>(null);

  const { loading: loadingMessage, messages } = useLatestOutreachMessage(candidate?.id!);
  const { loading: loadingPhoneSummary, phoneConversation } = usePhoneConversationSummary(
    candidate?.id!
  );

  if (!candidate) {
    return null;
  }

  return (
    <div className={styles.profileContainer}>
      <CandidateHeaderDetails candidate={candidate} />
      <Tabs
        value={selectedTab}
        sx={{ backgroundColor: 'transparent' }}
        onChange={(e, val: string | number | null) => setSelectedTab(val as typeof selectedTab)}
      >
        <TabList className={styles.tabList} size="sm">
          <Tab value={TabEnum.Profile}>Profile</Tab>
          {renderReasoning && (
            <Tab value={TabEnum.Reasoning}>
              <>
                <AutoAwesomeOutlinedIcon fontSize="small" />
                AI Reasoning
              </>
            </Tab>
          )}
          {renderMessages && <Tab value={TabEnum.Message}>Messages</Tab>}
          {renderPhoneSummary && <Tab value={TabEnum.PhoneSummary}>Phone Summary</Tab>}
        </TabList>
        <TabPanel value={TabEnum.Profile}>
          <CandidateProfileDetails candidate={candidate} />
        </TabPanel>
        <TabPanel value={TabEnum.Message}>
          {loadingMessage ? (
            <SpinnerLoader />
          ) : (
            messages &&
            messages.length > 0 && (
              <Box sx={{ display: 'flex', gap: 2 }}>
                {/* Left Panel with Message List */}
                <Box sx={{ width: '300px' }}>
                  {messages.map(message => (
                    <Card
                      key={message.id}
                      variant="outlined"
                      sx={{
                        cursor: 'pointer',
                        mb: 1,
                        '&:hover': { bgcolor: 'background.level1' },
                        bgcolor:
                          selectedMessageId === message.id
                            ? 'background.level2'
                            : 'background.surface',
                      }}
                      onClick={() => {
                        if (message.id) {
                          setSelectedMessageId(message.id);
                        }
                      }}
                    >
                      <CardContent>
                        <Typography level="title-sm" gutterBottom>
                          {message.subject || 'No Subject'}
                        </Typography>
                        <Typography level="body-sm" noWrap>
                          {message.message}
                        </Typography>
                        <Typography level="body-xs">
                        Send Date: {message.sentTime
                          ? new Date(message.sentTime).toLocaleString()
                          : ''}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                </Box>

                {selectedMessageId && (
                  <Box sx={{ flex: 1 }}>
                    <Typography level="title-lg" gutterBottom>
                      {messages.find(m => m.id === selectedMessageId)?.subject ||
                        'No Subject Available'}
                    </Typography>
                    <ContentBox content={messages.find(m => m.id === selectedMessageId)?.message} />
                  </Box>
                )}
              </Box>
            )
          )}
        </TabPanel>

        <TabPanel value={TabEnum.Reasoning}>
          <ContentBox content={llmReasoning} />
        </TabPanel>
        <TabPanel value={TabEnum.PhoneSummary}>
          {loadingPhoneSummary ? (
            <SpinnerLoader />
          ) : (
            <ContentBox content={phoneConversation?.summary} />
          )}
        </TabPanel>
      </Tabs>
    </div>
  );
}
