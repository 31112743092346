import { Box } from '@mui/joy';
import { useState } from 'react';
import { SearchBar } from '../components/search-bar/SearchBar';
import { generateProjectCriteria } from '../../../../api/api';
import { useAppStore } from '../../../../store/appStore';
import { useSnackbar } from '../../../../components/Snackbar/SnackbarContext';
import { ProjectCriteriaValues } from 'api-types';
import { DraftCriteriaModal } from './DraftCriteria';

export function SearchOnboardingPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const projectId = useAppStore(state => state.activeProjectId);
  const [submitting, setSubmitting] = useState(false);
  const [draftCriteria, setDraftCriteria] = useState<ProjectCriteriaValues | undefined>();
  const { showErrorSnackbar } = useSnackbar();
  const [draftCriteriaModalOpen, setDraftCriteriaModalOpen] = useState(false);

  const generateCriteria = async () => {
    setSubmitting(true);
    try {
      const { criteria } = await generateProjectCriteria({
        projectId: projectId,
        searchQuery: searchQuery,
      });
      setDraftCriteria(criteria);
      setDraftCriteriaModalOpen(true);
    } catch (error) {
      showErrorSnackbar('Failed to process your request, please try again');
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmit = () => {
    generateCriteria();
  };

  return draftCriteriaModalOpen && draftCriteria ? (
    <DraftCriteriaModal
      open={draftCriteriaModalOpen}
      onClose={() => setDraftCriteriaModalOpen(false)}
      draftCriteria={draftCriteria}
    />
  ) : (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
      <SearchBar
        searchQuery={searchQuery}
        onChange={setSearchQuery}
        onSubmit={handleSubmit}
        submitting={submitting}
      />
    </Box>
  );
}
